import { useRoutes } from "react-router-dom";
import Layout from "src/components/ui/app-layout";

import { BookmarksRoutes } from "src/features/bookmarks";
import { DialogueRoutes } from "src/features/dialogues";
import { InteractiveSxsConversationRoutes } from "src/features/interactive-sxs-conversations";
import { InteractiveSxsTaskRoutes } from "src/features/interactive-sxs-tasks";
import { MiscRoutes } from "src/features/misc";
import { StaticSxsConversationRoutes } from "src/features/static-sxs-conversations";
import { StaticSxsTaskRoutes } from "src/features/static-sxs-tasks";
import { TaskRoutes } from "src/features/tasks";

import { RequireAuthProvider } from "src/libs/auth";
import { AuthRoutes } from "./features/auth";

const RequiredDefaultAuth = () => {
  return (
    <RequireAuthProvider>
      <Layout />
    </RequireAuthProvider>
  );
};

// no longer using Prolific for authentication

// const RequiredProfilicAuth = () => {
//   return (
//     <RequireAuthProvider loginUrl={window.settings.PROLIFIC_LOGIN_URL}>
//       <Layout />
//     </RequireAuthProvider>
//   );
// };

const ROUTES = [
  {
    path: "/auth/*",
    element: <AuthRoutes />,
  },
  {
    path: "/app",
    element: <RequiredDefaultAuth />,
    children: [
      { path: "bookmarks/*", element: <BookmarksRoutes /> },
      { path: "dialogues/*", element: <DialogueRoutes /> },
      {
        path: "isxs/conversation/*",
        element: <InteractiveSxsConversationRoutes />,
      },
      {
        path: "static-conversations/*",
        element: <StaticSxsConversationRoutes />,
      },
    ],
  },
  {
    path: "/task",
    element: <RequiredDefaultAuth />,
    children: [{ path: "*", element: <TaskRoutes /> }],
  },
  {
    path: "/interactive-task",
    element: <RequiredDefaultAuth />,
    children: [{ path: "*", element: <InteractiveSxsTaskRoutes /> }],
  },
  {
    path: "/static-task",
    element: <RequiredDefaultAuth />,
    children: [{ path: "*", element: <StaticSxsTaskRoutes /> }],
  },
  {
    path: "*",
    element: <MiscRoutes />,
  },
];

export default function AppRoutes() {
  return useRoutes(ROUTES);
}
